import { capitalize, get, snakeCase } from "lodash";
import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-pro";
import {
  CHECKBOX_COLUMN_CONFIG,
  CHECKBOX_COLUMN_CONFIG_NEW_STYLE,
  GRID_DETAIL_PANEL_TOGGLE_COLUMN_CONFIG,
  GRID_OPERATORS,
} from "./constants";
import {
  numberCellClassName,
  numberFormatter,
  emptyCellFormatter,
  booleanFormatter,
  dateFormatter,
  listFormatter,
} from "./formatters";
import { CHGridColDef } from "./types";
import { GridListCell } from "./atoms";
import { GridFilterAutocomplete } from "./organisms/GridFilterAutocomplete";
import { ACTIONS_COLUMN_CONFIG, ACTIONS_COLUMN_CONFIG_NEW_STYLE } from "./actionsColumnConfig";
import { GridFilterDateInput } from "./molecules/GridFilterDateInput";

function getColumnOperators(column: CHGridColDef, timezone?: string) {
  const type = column.type || "string";

  if (column.customFilters) {
    return column.filterOperators;
  }

  if (type === "string") {
    const stringRemovedOperators = ["isAnyOf", "isEmpty"].concat(column.removedOperators || []);

    let operators = getGridStringOperators().filter(
      ({ value }) => !stringRemovedOperators.includes(value)
    );

    if (column.getFilterSuggestions) {
      operators = operators.map((operator) => {
        if (["contains", "equal"].includes(operator.value)) {
          return {
            ...operator,
            InputComponent: GridFilterAutocomplete,
            InputComponentProps: { getFilterSuggestions: column.getFilterSuggestions },
          };
        }

        return operator;
      });
    }

    return operators;
  }

  if (type === "number") {
    const numberRemovedOperators = ["isAnyOf", "isEmpty"].concat(column.removedOperators || []);

    return getGridNumericOperators().filter(({ value }) => !numberRemovedOperators.includes(value));
  }

  if (type === "date" || type === "dateTime") {
    const dateTimeRemovedOperators = ["is", "not"].concat(column.removedOperators || []);
    const isDateTime = type === "dateTime";

    return getGridDateOperators(isDateTime)
      .filter(({ value }) => value !== "isEmpty")
      .filter(({ value }) => {
        if (!isDateTime) {
          return true;
        }
        return !dateTimeRemovedOperators.includes(value);
      })
      .map((operator) =>
        operator.value !== "isNotEmpty"
          ? {
              ...operator,
              InputComponent: GridFilterDateInput,
              InputComponentProps: { ...operator.InputComponentProps, timezone },
            }
          : operator
      );
  }

  if (type === "list") {
    const listRemovedOperators = column.removedOperators || [];

    return getGridStringOperators()
      .filter(
        ({ value }) =>
          ["contains", "isNotEmpty"].includes(value) && !listRemovedOperators.includes(value)
      )
      .map((operator) =>
        operator.value === "contains"
          ? { ...operator, InputComponent: GridFilterAutocomplete }
          : operator
      )
      .toSpliced(1, 0, GRID_OPERATORS.notContains);
  }

  return [];
}

// Adds default formatting based on types
// Fills with default properties if missing
// Adds checkbox and actions columns
export function normalizeColumnDefinitions(
  columns: CHGridColDef[],
  {
    dateFormat,
    timezone,
    hideActions,
    disableSelectAll,
    newStyle,
  }: {
    dateFormat?: string;
    timezone?: string;
    hideActions?: boolean;
    disableSelectAll?: boolean;
    newStyle?: boolean;
  }
): CHGridColDef[] {
  const actionsColumn = {
    ...(newStyle ? ACTIONS_COLUMN_CONFIG_NEW_STYLE : ACTIONS_COLUMN_CONFIG),
    ...columns.find((column) => column.type === "actions"),
  };

  const result: CHGridColDef[] = columns
    .filter((column) => column.field !== "actions")
    .map((column) => {
      const type = column.type || "string";
      const finalColumn: CHGridColDef = {
        ...column,
        ...getColumnSize(column),
        headerName: column.headerName || createLabelFromField(column.field),
        type,
        cellClassName: getCellClassNameByType(column),
        valueFormatter: getValueFormatterByType(column, { dateFormat, timezone }),
        valueGetter: getValueGetterByType(column),
      };

      finalColumn.filterOperators = getColumnOperators(column, timezone);

      if (type === "list") {
        if (!finalColumn.renderCell) {
          finalColumn.renderCell = GridListCell;
        }
        finalColumn.sortable = false;
      }

      return finalColumn;
    });

  return [
    GRID_DETAIL_PANEL_TOGGLE_COLUMN_CONFIG,
    {
      ...(newStyle ? CHECKBOX_COLUMN_CONFIG_NEW_STYLE : CHECKBOX_COLUMN_CONFIG),
      ...CHECKBOX_COLUMN_CONFIG,
      renderHeader: disableSelectAll ? () => null : CHECKBOX_COLUMN_CONFIG.renderHeader,
    },
    ...result,
    ...(hideActions ? [] : [actionsColumn]),
  ];
}

export function createLabelFromField(field: string): string {
  // Handle dot separated object path
  const propName = field.split(".").at(-1);
  const snakeCaseName = snakeCase(propName);

  return capitalize(snakeCaseName.split("_").join(" "));
}

function getCellClassNameByType(column: CHGridColDef) {
  if (column.type === "number") {
    return numberCellClassName(column.cellClassName);
  }

  return undefined;
}

function getValueFormatterByType(
  column: CHGridColDef,
  { dateFormat, timezone }: { dateFormat?: string; timezone?: string }
) {
  if (column.valueFormatter) {
    return column.valueFormatter;
  }

  if (column.type === "number") {
    return numberFormatter();
  }

  if (column.type === "boolean") {
    return booleanFormatter();
  }

  if (column.type === "date" || column.type === "dateTime") {
    return dateFormatter({ timezone, dateFormat });
  }

  if (column.type === "list") {
    return listFormatter();
  }

  return emptyCellFormatter();
}

function getValueGetterByType(column: CHGridColDef) {
  if (column.valueGetter) {
    return column.valueGetter;
  }

  return ({ row, field }: any) => {
    const resolvedValue = get(row, field, null);

    if (column.type === "date" || column.type === "dateTime") {
      return resolvedValue && new Date(resolvedValue);
    }

    return resolvedValue;
  };
}

function getColumnSize(column: CHGridColDef) {
  const size: Pick<CHGridColDef, "width" | "minWidth" | "maxWidth" | "flex"> = {};

  if (column.resizable === false) {
    return size;
  }

  if (!column.flex && !column.width) {
    size.flex = 1;
  }

  if (!column.minWidth) {
    size.minWidth = ["date", "dateTime"].includes(column.type!) ? 200 : 140;
  }

  return size;
}
